<template>
  <div class="payments">
    <div class="content">
      <div class="title">
        <span>Payment methods</span>
        <div class="plus-icon">
          <img src="../assets/icons/Plus-Accent.svg" />
        </div>
      </div>
      <div class="cards">
        <Card />
      </div>
      <div class="add">
        <div class="form-group">
          <label for="number" class="label">Add new card</label>
          <input
            id="number"
            type="text"
            class="text-input"
            placeholder="XXXX-XXXX-XXXX-XXXX"
          />
        </div>
        <div class="horizontal">
          <div class="form-group date">
            <label for="date" class="label">Expiration</label>
            <input
              id="date"
              type="text"
              class="text-input"
              placeholder="XX-XX"
            />
          </div>
          <div class="form-group year">
            <label for="year" class="label">&nbsp;</label>
            <input
              id="year"
              type="text"
              class="text-input"
              placeholder="XXXX"
            />
          </div>
          <div class="form-group cvc">
            <label for="cvc" class="label">CVC</label>
            <input id="cvc" type="text" class="text-input" placeholder="XXX" />
          </div>
          <button class="primary">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
export default {
  name: "Payments",
  components: {
    Card
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
.payments {
  width: 100%;
  height: 100%;
  .content {
    display: flex;
    flex-direction: column;
    padding: 60px 184px;
    color: $bg-dark;
    .title {
      display: flex;
      align-items: center;
      span {
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -1.025px;
      }
      .plus-icon {
        margin-left: 8px;
      }
    }
    .cards {
      margin-top: 24px;
    }
    .add {
      margin-top: 32px;
      .form-group {
        margin-bottom: 32px;
      }
      .horizontal {
        display: flex;
        align-items: flex-end;
        .form-group {
          margin-bottom: 0;
        }
        .date {
          width: 100px;
        }
        .year {
          width: 88px;
          margin-left: 24px;
        }
        .cvc {
          width: 78px;
          margin-left: 77px;
        }
        button {
          width: 136px;
          margin-left: 32px;
        }
      }
    }
  }
}
</style>
