<template>
  <div
    class="card"
    :class="{
      selected: selectedCard === pmId,
      notselected: selectedCard && selectedCard !== pmId
    }"
  >
    <div class="brand">
      <img
        src="../assets/icons/Credit-Card-Visa.svg"
        v-if="card.brand === 'visa'"
      />
      <img
        src="../assets/icons/Credit-Card-MasterCard.svg"
        v-if="card.brand === 'MasterCard'"
      />
      <img
        src="../assets/icons/Credit-Card-Paypal.svg"
        v-if="card.brand === 'Paypal'"
      />
    </div>
    <div class="number">
      <div class="dots">
        <div v-for="i in 3" :key="i" class="dot-group">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
      <div class="digits">
        <span>{{ card.last4 }}</span>
      </div>
    </div>
    <div class="details">
      <span class="name">{{ card.name }}</span>
      <span class="expires">12/20</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    card: Object,
    selectedCard: String,
    pmId: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card {
  width: 144px;
  height: 94px;
  padding: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #2a2a2a;
  border: 3px solid transparent;
  border-radius: 10px;
  color: $white;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: -0.22px;
  transition: 0.2s;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &.selected {
    border: 3px solid $accent;
    box-sizing: border-box;
  }
  &.notselected {
    color: $text-additional;
    .brand {
      opacity: 0.3;
    }
    .number {
      .dots {
        .dot-group {
          .dot {
            background: $text-additional;
          }
        }
      }
    }
  }
  &:last-child {
    margin-right: 0;
  }
  .brand {
    flex: 1;
  }
  .number {
    flex: 1;
    display: flex;
    align-items: center;
    .dots {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
      .dot-group {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2px;
        .dot {
          width: 4px;
          height: 4px;
          background: $white;
          border-radius: 50%;
        }
      }
    }
    .digits {
      flex: 1;
      text-align: right;
    }
  }
  .details {
    flex: 1;
    display: flex;
    align-items: flex-end;
    .name {
      flex: 1;
      line-height: 10px;
    }
    .expires {
      line-height: 10px;
    }
  }
}
</style>
